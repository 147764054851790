<template>
	<CookieTemplateLayout
		:header-title="'Cookie Appearance Templates' | useLabels('Cookie Appearance Templates')"
		:header-caption="'Add, edit and manage cookie appearance templates' | useLabels('Add, edit and manage cookie appearance templates')"
	>
		<template #content>
			<FilterBar
				:search-query.sync="cookieAppearanceTemplateSearchQuery"
				search-query-label="Cookie Appearance Template Name"
				:selected-brand-id.sync="selectedAdminPortalBrandId"
				@persistSearchQuery="changeCookieAppearanceTemplateSearchQuery"
				@persistSelectedBrandId="changeAdminPortalBrandFilter"
			>
				<template #action>
					<!-- add permission -->
					<PrimaryActionButton
						v-if="userCanCreateUpdate"
						@click="onCreateClick()"
					>
						<v-icon left>
							mdi-plus
						</v-icon>
						Create
					</PrimaryActionButton>
				</template>
			</FilterBar>
			<SectionCard>
				<template #title>
					Cookie Appearance Templates
				</template>
				<template #body>
					<DataTable
						:headers="tableHeaders"
						:items="filteredCookieAppearanceTemplates"
						sort-by="id"
						:sort-desc="true"
						@click:row="onEditClick"
					>
						<template #item.forceBannerInteraction="{ item }">
							<v-icon small>
								{{ item.forceBannerInteraction ? 'mdi-check' : 'mdi-close' }}
							</v-icon>
						</template>
						<template #item.preBannerEnabled="{ item }">
							<v-icon small>
								{{ !item.preBanner.preBannerEnabled ? 'mdi-check' : 'mdi-close' }}
							</v-icon>
						</template>
						<template #item.gpcBannerEnabled="{ item }">
							<v-icon small>
								{{ item.gpcBanner.gpcBannerEnabled ? 'mdi-check' : 'mdi-close' }}
							</v-icon>
						</template>
						<template #item.autoShowModal="{ item }">
							<v-icon small>
								{{ item.banner.autoShowModal ? 'mdi-check' : 'mdi-close' }}
							</v-icon>
						</template>
						<template #item.action="{ item }">
							<IconButton
								v-if="!item.isSystem && userCanCreateUpdate"
								tooltip-text="Edit Cookie Appearance Template"
								@click="onEditClick(item)"
							>
								mdi-pencil
							</IconButton>
							<IconButton
								v-if="item.isSystem || !userCanCreateUpdate"
								tooltip-text="View Cookie Appearance Template"
								@click="onEditClick(item)"
							>
								mdi-eye
							</IconButton>
							<IconButton
								v-if="userCanCreateUpdate"
								tooltip-text="Clone Cookie Appearance Template"
								@click.stop.prevent="onCloneClick(item, true)"
							>
								mdi-content-duplicate
							</IconButton>
							<IconButton
								v-if="userCanDelete && item.usage === 0 && !item.isSystem"
								tooltip-text="Delete Cookie Appearance Template"
								@click.stop.prevent="cookieAppearanceTemplateToDelete = item"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
				</template>
			</SectionCard>
			<ConfirmDeleteModal
				v-if="cookieAppearanceTemplateToDelete && userCanDelete"
				:entity-name="cookieAppearanceTemplateToDelete.name"
				entity-type="Cookie Appearance Template"
				@close="cookieAppearanceTemplateToDelete = null"
				@delete="deleteCookieAppearanceTemplate"
			/>
		</template>
	</CookieTemplateLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import CookieTemplateLayout from '../cookie-template-layout.vue'
import FilterBar from '../../../../../../../shared/components/filter-bar.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import ConfirmDeleteModal from '../../../../../../../shared/components/confirm-delete-modal.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import { useLabels } from '../../../../../../../shared/state/admin-portal-navigation.js'
import { MANAGE_COOKIE_APPEARANCE_TEMPLATE } from '../../../../../router/route-names.js'
import { getCookieAppearanceTemplates, deleteCookieAppearanceTemplate } from '../../../../../../../shared/utils/api/cookies/cookie-appearance-templates.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { CAN_CREATE_UPDATE_COOKIE_APPEARANCE_TEMPLATES, CAN_DELETE_COOKIE_APPEARANCE_TEMPLATES } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import { changeCookieAppearanceTemplateSearchQuery, cookieAppearanceTemplateSearchQuery } from '../../../../../../../shared/state/cookie-appearance-templates.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../../../shared/state/brands.js'
export default {
	components: {
		CookieTemplateLayout,
		FilterBar,
		PrimaryActionButton,
		IconButton,
		SectionCard,
		ConfirmDeleteModal,
		DataTable
	},
	setup () {
		return {
			changeCookieAppearanceTemplateSearchQuery,
			cookieAppearanceTemplateSearchQuery,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId
		}
	},
	data () {
		return {
			cookieAppearanceTemplates: [],
			cookieAppearanceTemplateToDelete: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_COOKIE_APPEARANCE_TEMPLATES)
		},
		userCanDelete () {
			return this.productAreaPermission(CAN_DELETE_COOKIE_APPEARANCE_TEMPLATES)
		},
		tableHeaders () {
			return [
				{ value: 'id', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '10%' },
				{ value: 'name', text: 'Cookie Appearance Template Name', width: '15%' },
				{ value: 'forceBannerInteraction', text: 'Force Banner Interaction', width: '10%' },
				{ value: 'preBannerEnabled', text: 'Pre-Banner Hidden', width: '10%' },
				{ value: 'gpcBannerEnabled', text: 'GPC Banner Enabled', width: '10%' },
				{ value: 'autoShowModal', text: 'Auto Show Modal', width: '10%' },
				{ value: 'usage', text: 'Usage', width: '5%' },
				{ value: 'createdDate', text: 'Created Date', width: '10%' },
				{ value: 'action', text: 'Action', width: '10%', sortable: false }
			]
		},
		filteredCookieAppearanceTemplates () {
			return this.cookieAppearanceTemplates.filter(({ name, brandId }) => {
				let check = true
				if (cookieAppearanceTemplateSearchQuery.value) check = name.toLowerCase().includes(cookieAppearanceTemplateSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				return check
			}).map(appearanceTemplate => ({
				...appearanceTemplate
			}))
		}
	},
	created () {
		this.getCookieAppearanceTemplates()
	},
	methods: {
		onCreateClick () {
			this.$router.push({
				name: MANAGE_COOKIE_APPEARANCE_TEMPLATE
			})
		},
		onEditClick (item) {
			this.$router.push({
				name: MANAGE_COOKIE_APPEARANCE_TEMPLATE,
				params: {
					cookieAppearanceTemplateToEdit: item
				}
			})
		},
		onCloneClick (item, cloneMode) {
			const cookieAppearanceTemplateToEdit = JSON.parse(JSON.stringify(item))
			cookieAppearanceTemplateToEdit.name = cookieAppearanceTemplateToEdit.name + ' (Cloned)'
			this.$router.push({
				name: MANAGE_COOKIE_APPEARANCE_TEMPLATE,
				params: {
					cookieAppearanceTemplateToEdit,
					cloneMode: cloneMode
				}
			})
		},
		async getCookieAppearanceTemplates () {
			const { cookieAppearanceTemplates } = await getCookieAppearanceTemplates()
			this.cookieAppearanceTemplates = cookieAppearanceTemplates
		},
		async deleteCookieAppearanceTemplate () {
			await deleteCookieAppearanceTemplate(this.cookieAppearanceTemplateToDelete.id)
			showSnackbar('You have removed this cookie appearance template')
			this.cookieAppearanceTemplateToDelete = null
			this.getCookieAppearanceTemplates()
		}
	}
}

</script>
